* {
  outline: none !important;
  box-sizing: border-box;
}

a {
  color: $link;
  text-decoration: none !important;
  transition: background ease 0.2s,
  border ease 0.2s,
  color ease 0.2s;
  &:hover,
  &:focus,
  &:active {
    color: $primarycolor;
    text-decoration: underline;
    outline: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

html {
  height: 100vh;
}

section {
  @include clearfix;
}

header,
footer {
  flex: 0 0 auto;
}


footer {
  left: 0;
  width: 100%;
  flex-shrink: 0;
}


.container {
  flex: 1 0 auto;
}


body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#video {
  width: 100%;
  height: 100%;
}
