.icon {
  &-fbm {
    width: 30px;
    height: 20px;
    margin-right: 10px;
    fill: $white
  }
  &-telega {
    width: 30px;
    height: 20px;
    margin-right: 10px;
    fill: $white
  }
}