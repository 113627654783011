.privacy-policy {
  h3 {
    margin-top: 0;
    margin-bottom: 45px;
    color: #ee2024;
    font-size: 24px;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }
}
