@import "sections/page-videos";
@import "sections/video-intro";
@import "sections/bootstrap-helper";

/* main styles for page */
main {
  display: flex;
  flex: 1 0 auto;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  &:before {
    background: rgba($menu-h, 0.9);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.1s ease-in-out 0.1s;
    transition: all 0.1s ease-in-out 0.1s;
    z-index: 10;
    .open-nav & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.faqHeader {
  margin: 20px;
  font-size: 27px;
}

.casual-list {
  list-style: initial;
  padding-left: 15px;
}
