.copyright {
  background: $footer-bg;
  color: #666;
  padding: 15px 0 0 0;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;

  .copyright-text .textwidget, .menu-copyright-links-container {
    float: left;
    text-align: center;
  }

  .copyright-text {
    @include mediamax(767px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    margin-bottom: 10px;
    @media (min-width: 992px) {
      margin-bottom: auto;
    }

    a {
      float: left;
    }
  }

  a {
    color: #999;
    &:hover {
      color: #ccc;
    }
  }
  .menu-copyright-links {
    @include mediamax(767px) {
      display: flex;
      justify-content: center;
    }
  }
  .menu-copyright-links li {
    float: left;
    a {
      border-left: 2px solid #454545;
      padding-left: 10px;
      margin-left: 10px;
    }
    &:first-child a {
      border-left: 0;
      @include mediamax(767px) {
        margin: 0;
        padding: 0;
      }
    }
  }

  .contact-list {
    &__item {
      display: inline-block;
      padding: 0 5px;
      white-space: nowrap;
    }
  }

  .action {
    text-align: right;

    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-top: -12px;
      font-size: 20px;
      text-align: center;

      i {
        margin: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .copyright {
    .contact-list {
      text-align: center;
    }

    .action {
      margin-top: 10px;
      text-align: center;
    }
  }
}

.lightbox,
.popup {
  opacity: 0;
  transition: all .5s ease;
}

.lightbox {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
}

.popup {
  position: fixed;
  visibility: hidden;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border-radius: 5px;

  background-color: #EE2024;

  @media screen and (max-width: 480px) {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
  }

  .gmap {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.47);
  }

  .info {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.popup-visible,
.lightbox-visible {
  visibility: visible;
  opacity: 1;
  transition: all .5s ease;
}
