.panel-heading {
  .panel-title {
    a {
      display: block;
      margin: -10px -15px;
      padding: 10px 15px;
    }
  }
}

.panel-heading [data-toggle="collapse"].collapsed::after {
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	color: #454444;
}

.panel-heading [data-toggle="collapse"]::after {
	font-family: 'FontAwesome';
	content: "\f0d7";
	float: right;
	color: #F58723;
	font-size: 24px;
	line-height: 24px;
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
