/* Page background video styling */
.body-video {
  width: 100%;
  clear: both;
  opacity: 0.25;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;

  video {
    min-height: 100%;
    width: auto;
    height: auto;
    @include centered;

    /* Adjustment for mobile */
    @media (max-width: 1280px) {
      width: 100%;
    }
  }
}

/* END */

/* Popup video styling */
.popup-video {
  background: $white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: none;
}

.popup-video .embed-responsive {
  max-width: 1200px;
  width: 100%;
  @include centered;
}

.btn-close {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  background: $primarycolor;
  color: $white;
  padding: 6px 32px 4px 10px;
  font-size: 12px;
  opacity: 1;
  font-weight: 700;
  cursor: pointer;
}

.btn-close:after {
  background: url('../img/close-icon.png') no-repeat 0 0;
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  right: 5px;
  top: 5px;
}

.btn-close:hover {
  background: #D65E4E;
}

@include respond-to(small) {
  .btn-close {
    right: auto;
    left: 0
  }
}

/* END */
