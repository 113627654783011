/* Header and navbar styling */

.header {
  &__logo {
    text-decoration: none;
    float: left;
    margin: 15px 0;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    height: 80px;
    img {
      max-width: inherit;
      max-height: 100%;
    }
    @include respond-to(medium) {
      max-width: 180px;
      height: 60px;
    }
    @include respond-to(small) {
      max-width: 140px;
      height: 50px;
    }
  }
  &__navbar {
    padding: 0;
    &--nav {
      float: right;
      font-family: $basefont;
      margin: 0;
      list-style-type: none;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      & > li {
        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          display: none;
        }
        &:first-child {
          a span {
            border-left: 0;
          }
        }
        a {
          display: block;
          border-bottom: 7px solid transparent;
          text-transform: uppercase;
          padding: 46px 0 12px;
          span {
            padding: 5px 12px;
            border-left: 1px dotted $gray;
            @include respond-to(medium) {
              padding: 5px;
            }
            @include respond-to(small) {
              border: none;
              padding: 0
            }
          }
          &:hover,
          &:focus {
            background: $menu-h;
            border-color: $gray;
            color: $linkh;
          }
        }
        &:last-child {
          a {
            span {
              border: none;
            }
          }
        }
        @include respond-to(medium) {
          font-size: 12px;
          a {
            padding-top: 30px
          }
        }

        @include respond-to(small) {
          &:nth-last-of-type(1),
          &:nth-last-of-type(2) {
            display: block;
          }
          border-bottom: 1px dotted $gray;
          width: 100%;
          clear: both;
          a {
            padding: 10px 20px 7px;
            border-bottom: 3px solid transparent;
          }
        }

      }
      @include respond-to(small) {
        padding-top: 60px;
        width: 260px;
        height: 100%;
        text-align: right;
        background: $white;
        position: fixed;
        right: -260px;
        top: 0;
        z-index: 999;
        -webkit-transition: all linear 0.2s;
        -o-transition: all linear 0.2s;
        transition: all linear 0.2s;
      }
      .open-nav & {
        right: 0;
      }
    }
    &--toggle {
      position: absolute;
      right: 10px;
      top: 30px;
      z-index: 1000;
      margin: 0;
      padding: 5px;
      width: 35px;
      height: 30px;
      .icon-bar {
        background: $linkh;
        transition: all 0.2s;
      }
      .open-nav & {
        position: fixed;
        .icon-bar:nth-child(1n) {
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }
        .icon-bar:nth-child(2n) {
          opacity: 0;
        }
        .icon-bar:nth-child(3n) {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }
    }
  }
}

/* END */



