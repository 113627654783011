.video-intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  min-height: 450px;
  text-align: center;
  padding: 20px 0;
  h1 {
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 20%;
    width: 100%;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    padding-bottom: 0;
    @include respond-to(medium) {
      font-size: 26px;
    }
    @include respond-to(small) {
      font-size: 22px;
    }
    span {
      display: block;
      font-weight: 400;
    }
    i {
      font-weight: 300;
      font-style: normal;
    }
  }
}

.video-play {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  display: block;
  cursor: pointer;
  color: $red;
  width: 150px;
  .fa {
    font-size: 90px;
    border-radius: 50%;
    border: none;
    line-height: normal;
  }
  .fa-play {
    @include centered;
    font-size: 30px;
    margin-left: 4px
  }
  &:hover {
    color: $primarycolor;
  }
}

.buttons-container {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 75%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
}
.page-button {
  a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 170px;
    padding: 10px;
    margin: 0 20px;
    color: $white;
    background: $lightgray;
    @include respond-to(small) {
      margin: 0 5px ;
      width: 100px;
      .icon {
        margin: 0;
      }
    }
    &:hover {
      background: $primarycolor;
    }
  }
}
