@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

.faa-parent.animated-hover:hover > .faa-spin, .faa-spin.animated, .faa-spin.animated-hover:hover {
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite
}

.faa-parent.animated-hover:hover > .faa-spin.faa-fast, .faa-spin.animated-hover.faa-fast:hover, .faa-spin.animated.faa-fast {
  -webkit-animation: spin .7s linear infinite;
  animation: spin .7s linear infinite
}

.faa-parent.animated-hover:hover > .faa-spin.faa-slow, .faa-spin.animated-hover.faa-slow:hover, .faa-spin.animated.faa-slow {
  -webkit-animation: spin 2.2s linear infinite;
  animation: spin 2.2s linear infinite
}